import { createAction } from '@reduxjs/toolkit';

export const FETCH_TIPOS_USUARIOS_REQUEST = 'FETCH_TIPOS_USUARIOS_REQUEST';
export const FETCH_TIPOS_USUARIOS_SUCCESS = 'FETCH_TIPOS_USUARIOS_SUCCESS';
export const FETCH_TIPOS_USUARIOS_FAILURE = 'FETCH_TIPOS_USUARIOS_FAILURE';
export const SET_TYPE_USUARIOS_PAGE_USERS = 'SET_TYPE_USUARIOS_PAGE_USERS';
export const SET_TYPE_USUARIOS_SORT_USERS = 'SET_TYPE_USUARIOS_SORT_USERS';
export const CREATE_TYPE_USER_REQUEST = createAction('CREATE_TYPE_USER_REQUEST');
export const CREATE_TYPE_USER_SUCCESS = createAction<any>('CREATE_TYPE_USER_SUCCESS');
export const CREATE_TYPE_USER_FAILURE = createAction<string>('CREATE_TYPE_USER_FAILURE');
export const UPDATE_TYPE_USER_REQUEST_USERS = 'UPDATE_TYPE_USER_REQUEST_USERS';
export const UPDATE_TYPE_USER_SUCCESS_USERS = 'UPDATE_TYPE_USER_SUCCESS_USERS';
export const UPDATE_TYPE_USER_FAILURE_USERS = 'UPDATE_TYPE_USER_FAILURE_USERS';
export const DELETE_TYPE_USER_REQUEST_USERS = 'DELETE_TYPE_USER_REQUEST_USERS';
export const DELETE_TYPE_USER_SUCCESS_USERS = 'DELETE_TYPE_USER_SUCCESS_USERS';
export const DELETE_TYPE_USER_FAILURE_USERS = 'DELETE_TYPE_USER_FAILURE_USERS';
