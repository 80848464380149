import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../../store/store';
import fetchWithIP from "../../utils/fetchHeaders";
import { DesingChatActionTypes, FETCH_STYLES_FAILURE, FETCH_STYLES_REQUEST, FETCH_STYLES_SUCCESS, GET_DESING_CHAT_FAILURE, GET_DESING_CHAT_REQUEST, GET_DESING_CHAT_SUCCESS, UPDATE_DESING_CHAT_FAILURE, UPDATE_DESING_CHAT_REQUEST, UPDATE_DESING_CHAT_SUCCESS } from '../../../../constantes/chatBots/designChat/Design';


export const updateDesingChatRequestReducer = (): DesingChatActionTypes => ({
    type: UPDATE_DESING_CHAT_REQUEST
});

export const updateDesingChatSuccessReducer = (): DesingChatActionTypes => ({
    type: UPDATE_DESING_CHAT_SUCCESS,
    /* payload: data */
});

export const updateDesingChatFailureReducer = (error: string): DesingChatActionTypes => ({
    type: UPDATE_DESING_CHAT_FAILURE,
    payload: error
});

/* GET-ONE */
export const getDesingChatRequestReducer = (): DesingChatActionTypes => ({
    type: GET_DESING_CHAT_REQUEST
});

export const getDesingChatSuccessReducer = (data: any): DesingChatActionTypes => ({
    type: GET_DESING_CHAT_SUCCESS,
    payload: data
});

export const getDesingChatFailureReducer = (error: string): DesingChatActionTypes => ({
    type: GET_DESING_CHAT_FAILURE,
    payload: error
});

/* styles */

export const fetchStylesRequest = (): DesingChatActionTypes => ({
    type: FETCH_STYLES_REQUEST
});
export const fetchStylesSuccess = (styles:any): DesingChatActionTypes => ({
    type: FETCH_STYLES_SUCCESS,
    payload: styles,
});
export const fetchStylesFailure = (error:string): DesingChatActionTypes => ({
    type: FETCH_STYLES_FAILURE,
    payload: error,
});


export const UpdateDesingChatReducer = (
    objeto: any,
    iconoFile: File | null,
    logoFile: File | null
): ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
    //console.log("objeto a ACTUALIZAR", objeto);
    dispatch(updateDesingChatRequestReducer());
/*     dispatch(fetchStylesRequest()); */
    try {
        console.log(objeto, "3333333333333333333333 objeto --")
        const formData = new FormData();
        if (iconoFile !== null) formData.append('icono', iconoFile);
        if (logoFile !== null) formData.append('logo', logoFile);
        formData.append('tamanoLetra', objeto.fontSize);
        formData.append('fuente', objeto.fontFamily);
        formData.append('placeholder', objeto.inputPlaceholder);
        formData.append('colorCabecera', objeto.colorHeader);
        formData.append('colorTitulo', objeto.colorTitulo);
        formData.append('colorTextoEmisor', objeto.colorEmisor);
        formData.append('colorTextoReceptor', objeto.colorReceptor);
        formData.append('nombre', objeto.nombreChat);
        formData.append('estado', String(objeto.estado ? "true":"false"));
  
        const response = await fetchWithIP(
            'usuarios/' + localStorage.getItem("id_usuario") + '/chatbots/' + localStorage.getItem("chat_seleccionado") + '/diseno',
            { method: "PUT" },
            formData
        );

        const data = await response.json();

        dispatch(updateDesingChatSuccessReducer());
        /* dispatch(fetchStylesSuccess(data)); */
    } catch (error) {
        dispatch(updateDesingChatFailureReducer("error al actualizar el diseño del chatbot"));
 /*        dispatch(fetchStylesFailure("error al cargar los estilos")); */
    }
};


export const GetOneDesingChatReducer = ():
    ThunkAction<Promise<void>, RootState, unknown, Action<string>> => async (dispatch) => {
        dispatch(getDesingChatRequestReducer())
        try {
       /*      console.log("") */
            const result = await fetchWithIP(`usuarios/${localStorage.getItem("id_usuario")}/chatbots/${localStorage.getItem("chat_seleccionado")}`, { method: "GET" })
            const data = await result.json()
           /*  console.log(data, "data de reducer") */
            dispatch(getDesingChatSuccessReducer(data))
        } catch (error) {
            dispatch(getDesingChatFailureReducer("error al obtener el chatbot"))
        }
    }

