import { createAction } from '@reduxjs/toolkit';

export const FETCH_USUARIOS_REQUEST_USERS = 'FETCH_USUARIOS_REQUEST_USERS';
export const FETCH_USUARIOS_SUCCESS_USERS = 'FETCH_USUARIOS_SUCCESS_USERS';
export const FETCH_USUARIOS_FAILURE_USERS = 'FETCH_USUARIOS_FAILURE_USERS';
export const SET_USUARIOS_PAGE_USERS = 'SET_USUARIOS_PAGE_USERS';
export const SET_USUARIOS_SORT_USERS = 'SET_USUARIOS_SORT_USERS';
export const SET_USUARIOS_FILTERS = 'SET_USUARIOS_FILTERS';
export const UPDATE_USER_REQUEST_USERS = 'UPDATE_USER_REQUEST_USERS';
export const UPDATE_USER_SUCCESS_USERS = 'UPDATE_USER_SUCCESS_USERS';
export const UPDATE_USER_FAILURE_USERS = 'UPDATE_USER_FAILURE_USERS';
export const UPDATE_USER_STATUS_REQUEST_USERS = 'UPDATE_USER_STATUS_REQUEST_USERS';
export const UPDATE_USER_STATUS_SUCCESS_USERS = 'UPDATE_USER_STATUS_SUCCESS_USERS';
export const UPDATE_USER_STATUS_FAILURE_USERS = 'UPDATE_USER_STATUS_FAILURE_USERS';
export const CREATE_USER_REQUEST = createAction('CREATE_USER_REQUEST');
export const CREATE_USER_SUCCESS = createAction<any>('CREATE_USER_SUCCESS');
export const CREATE_USER_FAILURE = createAction<string>('CREATE_USER_FAILURE');
