import React, { useRef, useState } from 'react';
import { Table, Input, Button, Space, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef, TableColumnsType } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface TableGlobalProps {
  data: any[];
  columns: any[];
  loading_data: boolean;
  showTrash?: boolean;
  showDownload?: boolean;
  functionDelete: (keys: React.Key[], setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>) => void;
  pageTableGlobal: number;
  pageSizeTableGlobal: number;
  setPageTableGlobal: (page: number, pageSize?: number) => void;
  setPageSizeTableGlobal: (size: number) => void;
  actionOnRow: (record: any) => void;
}

const TableOne: React.FC<TableGlobalProps> = ({
  data,
  columns,
  loading_data,
  showTrash = true,
  showDownload = true,
  functionDelete,
  pageTableGlobal,
  pageSizeTableGlobal,
  setPageTableGlobal,
  setPageSizeTableGlobal,
  actionOnRow,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex === 'creadoen' || dataIndex === 'actualizadoen' ? (
          <RangePicker
            onChange={(dates, dateStrings) => {
              setSelectedKeys(dateStrings);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return (
    <>
      <Table
        scroll={{ x: 'max-content' }}
        className="Table-Global"
        columns={columns}
        dataSource={data}
        loading={loading_data}
        onRow={(record) => ({
          onClick: () => actionOnRow(record),
        })}
        rowClassName={(record) =>
          record.estid === 1 ? 'Table-Row-Global' : 'Table-Row-Disabled-Global'
        }
        pagination={{
          current: pageTableGlobal,
          pageSize: pageSizeTableGlobal,
          onChange: (page, pageSize) => {
            setPageTableGlobal(page, pageSize);
            if (pageSize) setPageSizeTableGlobal(pageSize);
          },
          position: ['topRight'],
        }}
      />
    </>
  );
};

export default TableOne;
