import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../config';

export interface FindAdminQuery {
  page?: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  sortColumn?: string;
  id?: number;
  nombre?: string;
  usuario?: string;
  nombrechatbot?: string;
  descripcion?: string;
  createdFrom?: string;
  createdTo?: string;
  updateFrom?: string;
  updateTo?: string;
}

export interface AdminData {
  id?: number;
  nombre?: string;
  usuario?: string;
  nombrechatbot?: string;
  descripcion?: string;
  createdFrom?: string;
  createdTo?: string;
  updateFrom?: string;
  updateTo?: string;
  // ... otros campos
}

export interface AdminResponse {
  data: AdminData[];
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

export const FETCH_ADMINS_REDUCER = createAsyncThunk<AdminResponse, FindAdminQuery, { rejectValue: string }>(
  'admin/fetchAdmins',
  async (query, { rejectWithValue }) => {
    try {
      const queryString = new URLSearchParams(query as any).toString();
      const response = await fetch(`${config.API_URL}admin?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data: AdminResponse = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message || 'An error occurred');
    }
  }
);