export const GET_DATA_CHATSBOTS_HOME = 'GET_DATA_CHATSBOTS_HOME'
export const BOT_SELECTED = 'BOT_SELECTED'
export const EDIT_VAR_MUNDO_HOME = 'EDIT_VAR_MUNDO_HOME'
export const GET_COUNT_CONVERSATIONS_HOME = 'GET_COUNT_CONVERSATIONS_HOME'
export const GET_COUNT_MESSAGES_HOME = 'GET_COUNT_MESSAGES_HOME'
export const GET_COUNT_MESSAGES_BY_USER_CHAT = 'GET_COUNT_MESSAGES_BY_USER_CHAT'
export const GET_COUNT_CONVERSATIONS_BY_USER_CHAT = 'GET_COUNT_CONVERSATIONS_BY_USER_CHAT'
export const GET_AVERAGE_CONVERSATIONS_MESSAGES = 'GET_AVERAGE_CONVERSATIONS_MESSAGES'
export const GET_AVERAGE_CONVERSATIONS_MESSAGES_BY_USER_CHAT = 'GET_AVERAGE_CONVERSATIONS_MESSAGES_BY_USER_CHAT'


export const DELETE_CHATBOT_REQUEST = 'DELETE_CHATBOT_REQUEST';
export const DELETE_CHATBOT_SUCCESS = 'DELETE_CHATBOT_SUCCESS';
export const DELETE_CHATBOT_FAILURE = 'DELETE_CHATBOT_FAILURE';
