import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_ADMINS_REDUCER, AdminData, AdminResponse } from '../../actions/admin/adminActions';

interface AdminState {
  rex_admins: AdminData[];
  loading: boolean;
  error: string | null;
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

const initialState: AdminState = {
  rex_admins: [],
  loading: false,
  error: null,
  meta: {
    total: 0,
    page: 1,
    limit: 10,
    totalPages: 0,
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FETCH_ADMINS_REDUCER.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FETCH_ADMINS_REDUCER.fulfilled, (state, action: PayloadAction<AdminResponse>) => {
        state.loading = false;
        state.rex_admins = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(FETCH_ADMINS_REDUCER.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? 'An error occurred';
      });
  },
});

export default adminSlice.reducer;
