import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress, Tooltip, Modal } from 'antd';
import TableOne from '../../../components/table/TableOne';
import {
  RobotOutlined,
  SettingOutlined,
  CommentOutlined
} from '@ant-design/icons';
import ChatComponent from '../../../components/chat/ChatComponent';
import { FETCH_ADMINS_REDUCER, FindAdminQuery } from '../../../redux/actions/admin/adminActions';
import { AppDispatch, RootState } from '../../../redux/store/store';
import { GetDataChatsBotsHomeReducer } from '../../../redux/actions/home/Home';
import { GetDataConversationsReducer } from '../../../redux/actions/chatBots/conversation/Conversation';
import { GetConversationReducer } from '../../../redux/actions/chatBots/Chat/Chat';

const TableAdmin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { rex_admins, loading, meta } = useSelector((state: RootState) => state.admin);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [chatbot_seleccionado, setChatbot_seleccionado] = useState<number | null>(null);
  const { rex_chatsbots } = useSelector((state: RootState) => state.home);
  const { rex_conversation_chat } = useSelector((state: RootState) => state.tabChat);
  const id_conversation = localStorage.getItem("TAB_CHAT_CONVERSACION_ID");


  useEffect(() => {
    dispatch(GetDataChatsBotsHomeReducer());
    const query: FindAdminQuery = { page: currentPage, limit: pageSize };
    dispatch(FETCH_ADMINS_REDUCER(query));
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    dispatch(GetDataConversationsReducer());
  }, [localStorage.getItem("chat_seleccionado")]);

  useEffect(() => {
    if (id_conversation) {
      GetConversation();
    }
  }, [id_conversation]);

  const selectedChatbot = rex_chatsbots.find((bot: any) => bot.id === chatbot_seleccionado);

  const showModal = (chatbotId: number) => {
    setChatbot_seleccionado(chatbotId);
    localStorage.setItem("chat_seleccionado", chatbotId.toString());
    GetConversation();
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const GetConversation = async () => {
    await dispatch(GetConversationReducer());
  }

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      align: 'center',
      render: (_: any, record: any, index: number) => (
        <div>
          {(currentPage - 1) * pageSize + index + 1}
        </div>
      )
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      align: 'center',
      render: (_: any, record: any) => (
        <div className='Table-Contain-Text-Global'>
          {record.nombre}
        </div>
      )
    },
    {
      title: 'Usuario',
      dataIndex: 'usuario',
      align: 'center',
      render: (_: any, record: any) => (
        <div className='Table-Contain-Text-Global'>
          {record.usuario}
        </div>
      )
    },
    {
      title: 'Nombre del Chatbot',
      dataIndex: 'nombrechatbot',
      align: 'center',
      render: (_: any, record: any) => (
        <div className='Table-Contain-Text-Global'>
          {record.nombrechatbot}
        </div>
      )
    },
    {
      title: '% de aceptación',
      dataIndex: 'porcentaje_aceptacion',
      render: (_: any, record: any) => (
        <div className='Table-Contain-Text-Global'>
          <Progress percent={0} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
        </div>
      )
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      align: 'center',
      width: '200px',
      render: (_: any, record: any) => (
        <div
          className='Table-Contain-Text-Global'
          style={{
            cursor: 'pointer'
          }}
          onClick={() => showDescription(record.descripcion)}
        >
          <div
            style={{
              overflow: 'hidden', textOverflow: 'ellipsis',
              whiteSpace: 'nowrap', width: '250px'
            }}
            title={record.descripcion}
          >
            {record.descripcion}
          </div>
        </div>
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      align: 'center',
      render: (_: any, record: any) => (
        <div className='Table-Contain-Text-Global'>
          <Tooltip title={'Chat'}>
            <RobotOutlined
              style={{ fontSize: '18px', marginRight: '10px', cursor: 'pointer' }}
              onClick={() => {
                showModal(record.id);
              }}
            />
          </Tooltip>
          <Tooltip title={'Configuración'} placement="bottom">
            <SettingOutlined style={{ fontSize: '18px', marginRight: '10px', cursor: 'pointer' }} />
          </Tooltip>
          <Tooltip title={'Duplicar'}>
            <CommentOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
          </Tooltip>
        </div>
      )
    },
  ];

  const showDescription = (description: string) => {
    Modal.info({
      title: 'Descripción',
      content: (
        <div>
          {description}
        </div>
      ),
      onOk() { },
    });
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  return (
    <>
      <TableOne
        data={rex_admins}
        columns={columns}
        loading_data={loading}
        showTrash={true}
        showDownload={true}
        functionDelete={(values, resetValues) => {
          // deleteUser(values, resetValues)
        }}
        pageTableGlobal={currentPage}
        pageSizeTableGlobal={pageSize}
        setPageTableGlobal={handlePageChange}
        setPageSizeTableGlobal={setPageSize}
        actionOnRow={() => { }}
      // total={meta.total}
      />
      <Modal
        title="InfoBot"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={500}
      >
        <ChatComponent
          idConversation={
            id_conversation
              ? parseInt(id_conversation.toString())
              : 0
          }
          data={rex_conversation_chat}
          editBubble={false}
          nombreChat={selectedChatbot?.nombre || ''}
          resetChat={true}
        />
      </Modal>
    </>
  );
};

export default TableAdmin;
