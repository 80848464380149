// utils/fetchWithIP.ts
import getIp from './getIp';
import config from '../../../config';

interface FetchOptions extends RequestInit {
    headers?: HeadersInit;
}

async function fetchWithIP(endpoint: string, options: FetchOptions = {}, data?: any): Promise<any> {
    const url = `${config.API_URL}${endpoint}`;
    const headers: HeadersInit = {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
        // Remueve 'Content-Type' para permitir que el navegador la maneje al enviar FormData
    };
    
    // Agregar IP solo si no es una solicitud GET
    if (options.method !== 'GET') {
        const ip = await getIp();
        headers['ip'] = ip;
    }

    if (options.method === 'PUT') {
        console.log("Método PUT detectado, enviando datos...");
        return await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data),
        });
    }


    // Configura el cuerpo de la solicitud
    let body: any;
    if (data instanceof FormData) {
        // Si data es FormData, no necesitamos modificar el cuerpo ni añadir Content-Type
        body = data;
    } else if (data && options.method !== 'GET') {
        // Para solicitudes que no son GET y no usan FormData, usamos JSON
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(data);
    }

    const response = await fetch(url, {
        method: options.method || "POST",
        headers,
        body,
    });

    return response;
}
export default fetchWithIP;