import config from '../../../config';
import fetchWithIP from '../utils/fetchHeaders';

export const SUBMIT_FORM_DATA = 'SUBMIT_FORM_DATA';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';

export const submitFormData = (formData: any) => ({
  type: SUBMIT_FORM_DATA,
  payload: formData,
});

export const updateFormData = (formData: any) => ({
  type: UPDATE_FORM_DATA,
  payload: formData,
});

export const sendFormDataToEndpoint = (formData: any) => {
  return async (dispatch: any) => {
    try {

      const chatbot  = {
        nombre: formData.chatName,
        descripcion: formData.chatbotDescription,
        mensajeInicial: formData.welcomeMessage,
        retrasoRespuesta: parseInt(formData.retrasoRespuesta, 10),
        animacionEscribir: formData.typingAnimation,
        comportamiento: formData.comportamiento,
      }

      const horarios = [
        { dia: 'lunes', horario: formData.mondayHours },
        { dia: 'martes', horario: formData.tuesdayHours },
        { dia: 'miércoles', horario: formData.wednesdayHours },
        { dia: 'jueves', horario: formData.thursdayHours },
        { dia: 'viernes', horario: formData.fridayHours },
        { dia: 'sábado', horario: formData.saturdayHours },
        { dia: 'domingo', horario: formData.sundayHours },
      ].filter(h => h.horario);

      const nuevo = { chatbot, horarios };

      const id_usuario = localStorage.getItem('id_usuario');

      console.log("body:" ,nuevo)
      const response = await fetchWithIP('usuarios/' + id_usuario + '/chatbots', {
        method: 'POST',
      },
        nuevo,
    );

      if (response.ok) {
        const result = await response.json();
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error('eror', error);
    }
  };
};


export const updateChatbotAndSchedules = (formData: any, chatbotId: number) => {
  return async (dispatch: any) => {
    try {

      const id_chatbot = localStorage.getItem('chat_seleccionado');

      const chatbot  = {
        nombre: formData.chatName,
        descripcion: formData.chatbotDescription,
        mensajeInicial: formData.welcomeMessage,
        comportamiento: formData.comportamiento,

      };

      const horarios = [
        { dia: 'lunes', horario: formData.mondayHours, chatbotId: id_chatbot },
        { dia: 'martes', horario: formData.tuesdayHours, chatbotId: id_chatbot },
        { dia: 'miércoles', horario: formData.wednesdayHours, chatbotId: id_chatbot },
        { dia: 'jueves', horario: formData.thursdayHours, chatbotId: id_chatbot },
        { dia: 'viernes', horario: formData.fridayHours, chatbotId: id_chatbot },
        { dia: 'sábado', horario: formData.saturdayHours, chatbotId: id_chatbot },
        { dia: 'domingo', horario: formData.sundayHours, chatbotId: id_chatbot },
      ].filter(h => h.horario);

      const nuevo = { ...chatbot,  // Incluir los campos del chatbot
        horarios };

     

        console.log("PUT body:", JSON.stringify(nuevo));
      
      const response = await fetchWithIP('chatbot/' + id_chatbot + '', {
        method: 'PUT',
      }, nuevo);

      if (response.ok) {
        const result = await response.json();
        return true;
      } else {
        return false;
      }

    } catch (error) {
      console.error('Error updating chatbot and schedules:', error);
    }
  };
};