import React, { useState, useEffect } from 'react';
import { 
  WechatOutlined, WechatWorkOutlined, SlidersOutlined, FormatPainterOutlined, InteractionOutlined, PieChartOutlined,
  OrderedListOutlined, UsbTwoTone, RobotOutlined
} from '@ant-design/icons';
import { Tabs } from 'antd';
import TabChat from './tabs/chat/TabChat';
import TabCreateEdit from './tabs/createEdit/TabCreateEdit';
import TabTrain from './tabs/train/TabTrain';
import TabConversations from './tabs/conversations/TabConversations';
import TabDesign from './tabs/design/TabDesign';
import TabAnalytics from './tabs/analytics/TabAnalytics';
import TabIntegration from './tabs/integrations/TabIntegration';
import TabKnowledgeBase from './tabs/knowledgeBase/TabKnowledgeBase';
import CardBot from '../../components/pages/home/CardBot';

const listTabs = [
  {
    icon: RobotOutlined,
    title: 'CHATBOTS',
    component: <CardBot />,
    key: '1'
  },
  {
    icon: WechatOutlined,
    title: 'CHAT',
    component: <TabChat />,
    key: '2'
  },
  {
    icon: WechatWorkOutlined,
    title: 'EDITAR',
    component: <TabCreateEdit />,
    key: '3'
  },
  {
    icon: SlidersOutlined,
    title: 'ENTRENAR',
    component: <TabTrain />,
    key: '4'
  },
  {
    icon: UsbTwoTone,
    title: 'BASE DE CONOCIMIENTOS',
    component: <TabKnowledgeBase />,
    key: '5'
  },
  {
    icon: OrderedListOutlined,
    title: 'CONVERSACIONES',
    component: <TabConversations />,
    key: '6'
  },
  {
    icon: FormatPainterOutlined,
    title: 'DISEÑO',
    component: <TabDesign />,
    key: '7'
  },
  {
    icon: PieChartOutlined,
    title: 'ANALYTICS',
    component: <TabAnalytics />,
    key: '8'
  },
  {
    icon: InteractionOutlined,
    title: 'INTEGRACIONES',
    component: <TabIntegration />,
    key: '9'
  },
];

const Chat: React.FC = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const [disabledTabs, setDisabledTabs] = useState<string[]>([]);

  const updateTabs = () => {
    const chatSeleccionado = localStorage.getItem('chat_seleccionado');
    if (chatSeleccionado) {
      setDisabledTabs([]);
    } else {
      setDisabledTabs(listTabs.map(tab => tab.key));
    }
  };

  useEffect(() => {
    updateTabs(); // Initial check

    const interval = setInterval(() => {
      updateTabs(); // Check periodically
    }, 1000); // Check every second

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <Tabs
      activeKey={activeTabKey}
      onChange={handleTabChange}
      tabPosition="left"
      items={listTabs.map((Tab) => ({
        key: Tab.key,
        label: <span><Tab.icon /> {Tab.title}</span>,
        children: React.cloneElement(Tab.component, { key: activeTabKey }),
        disabled: disabledTabs.includes(Tab.key),
      }))}
      tabBarStyle={{
        background: 'white',
        padding: '20px',
        borderRadius: '50px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(12, 12, 12, 0.2)'
      }}
    />
  );
};

export default Chat;
